<template>
  <div style="background: linear-gradient(to top, #FFF, #F8F9FD);height: 97.6%;">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/chartConfig' }">数据模型</el-breadcrumb-item>
          <el-breadcrumb-item>添加模型图表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <div style="display:flex;justify-content: space-between;align-items:center;">
          <div class="white-bg-card" style="height: 365px;">
            <div class="form-title-custom">基础信息</div>
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="图表名称" prop="graphName">
                    <el-input v-model="form.graphName" placeholder="请输入图表名称"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="图表单位" prop="graphUnit">
                    <el-input v-model="form.graphUnit" placeholder="请输入图表单位"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="图表属性" prop="graphAttribute">
                    <el-input v-model="form.graphAttribute" placeholder="请输入图表属性"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" v-if="graphCode=='bar-contrast-1'||graphCode=='bar-contrast-2'||
                  graphCode=='distribution-contrast-1'">
                <el-col :span="24">
                  <el-form-item label="图表属性2" prop="graphAttribute2">
                    <el-input v-model="form.graphAttribute2" placeholder="请输入图表属性2"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24" v-if="
                  graphCode=='bar-total-1'||graphCode=='pie-chart-total-1'||
                  graphCode=='distribution-trend-1'||graphCode=='distribution-trend-2'||
                  graphCode=='bar-contrast-1'||graphCode=='bar-contrast-2'||
                  graphCode=='distribution-contrast-1'||graphCode=='distribution-merge-trend-1'
                  ">
                  <el-form-item label="统计类别" prop="statisticsType">
                    <el-select v-model="form.statisticsType" placeholder="请选择统计类别" style="width:100%;">
                      <el-option
                        v-for="item in categorys"
                        :label="item.name"
                        :value="item.code"
                        :key="item.code"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="bottom-btn">
                <el-button type="primary" size="small" @click="$router.push('/chartConfig')">取消</el-button>
                <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
              </div>
            </el-form>
          </div>
          <div class="white-bg-card" style="margin-left: 20px;">
            <div class="form-title-custom">图表信息</div>
            <chart-library @graphCode="graphCodes" @statisticsDimension="statisticsDimensions"></chart-library>
          </div>
        </div>
        <!-- <bind-configuration></bind-configuration> -->
        <device-info @loading="loadings" @updateDeviceData="updateDeviceDatas"></device-info>
      </div>
    </div>
  </div>
</template>

<script>
import ChartLibrary from './chartLegend/chartLibrary'
import deviceInfo from './deviceInfo/add'
// import bindConfiguration from './deviceInfo/bindConfiguration'
export default {
  components: {
    ChartLibrary,
    deviceInfo,
    // bindConfiguration
  },
  data() {
    return {
      loading: false,
      statisticsDimension: 'realtime',
      graphCode: 'bar-realtime',
      form: {
        graphName: null,
        graphUnit: '',
        graphAttribute: '',
        graphAttribute2: '',
        statisticsType: '',
      },
      rules: {
        graphName: [{ required: true, message: "必填项", trigger: "blur" }],
        graphUnit: [{ required: true, message: "必填项", trigger: "blur" }],
        graphAttribute: [{ required: true, message: "必填项", trigger: "blur" }],
        graphAttribute2: [{ required: true, message: "必填项", trigger: "blur" }],
        statisticsType: [{ required: true, message: "必填项", trigger: "change" }],
      },
      categorys: [
        { code: 'daily', name: '单日' },
        { code: 'weekly', name: '最近7天' },
        { code: 'monthly', name: '最近30天' },
      ],
      deviceData: [],
    };
  },
  methods: {
    loadings(value) {
      this.loading =  value
    },
    updateDeviceDatas(value) {
      this.deviceData = value
    },
    statisticsDimensions(value) {
      this.statisticsDimension = value
    },
    graphCodes(value) {
      this.graphCode = value
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form
          if(this.deviceData.length==0) return this.$message.error('请选择设备')
          let equipmentName = this.deviceData.some(v=>!v.equipmentName)
          let tag = this.deviceData.some(v=>!v.attributes.tag)
          if(equipmentName) return this.$message.error('请选择设备')
          if(tag) return this.$message.error('请选择点表')

          for(let i=0;i<this.deviceData.length;i++) {
            let data = this.deviceData[i].attributes
            if(!data.unit) return this.$message.error('请输入单位')
            if(!data.formulaType) return this.$message.error('请选择点表值类型')
            if(!data.firstInput&&data.formulaType=='formula') return this.$message.error('请配置公式')
          }
          form = {
            graphName: this.form.graphName,
            unitAttribute: this.form.graphUnit,
            elemental1Attribute: this.form.graphAttribute,
            graphCode: this.graphCode,
            statisticsDimension: this.statisticsDimension,
            itemId: sessionStorage.getItem('itemId'),
            statisticsType: this.form.statisticsType ? this.form.statisticsType : "latest",
            equipments: [],
          }
          if(this.graphCode=='bar-contrast-1'||this.graphCode=='bar-contrast-2'||this.graphCode=='distribution-contrast-1') {
            form.elemental2Attribute = this.form.graphAttribute2
          }
          this.deviceData.forEach(v=>{
            let formula = v.attributes.firstInput
            v.attributes.formulaList.forEach(f=>{
              formula += f.tagName+f.textName
            })
            form.equipments.push({
              eid: v.eid,
              equipmentBrandCode: v.equipmentBrandCode,
              attributes: {
                formulaType: v.attributes.formulaType,
                formula: v.attributes.formulaType=='formula'?formula:'',
                tag: v.attributes.tag,
                unit: v.attributes.unit
              }
            })
          })
          // console.log(form)
          this.loading = true
          let apiParams = ''
          // 实时
          if(this.graphCode=='bar-realtime-1'||this.graphCode=='bar-realtime-2'||this.graphCode=='distribution-realtime-1'||this.graphCode=='distribution-realtime-2') apiParams = 'dataModelGraphRealtimeCreate'
          // 累计统计
          if(this.graphCode=='bar-total-1'||this.graphCode=='pie-chart-total-1') apiParams = 'dataModelGraphAccumPerCreate'
          // 趋势统计
          if(this.graphCode=='distribution-trend-1'||this.graphCode=='distribution-trend-2') apiParams = 'dataModelGraphTrendCreate'
          // 对比统计
          if(this.graphCode=='bar-contrast-1'||this.graphCode=='bar-contrast-2'||this.graphCode=='distribution-contrast-1') apiParams = 'dataModelGraphTrendCreate'
          // 合并趋势统计
          if(this.graphCode=='distribution-merge-trend-1') apiParams = 'dataModelGraphMergeTrendCreate'
          this.$ajax.post(apiParams, form).then((res) => {
            this.loading = false
            this.$message.success("成功");
            this.$router.push("/chartConfig");
          }).catch(err=>{
            this.loading = false
          })
        }
      });
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 0px 50px;
}
</style>